<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE CATEGORY SERVICE</v-card-title>
        <form @submit.prevent="CreateData" enctype="multipart/form-data" ref="form">
          <v-row>
            <v-col cols="12" v-if="errors.length">
              <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                label="Category Service Name"
                v-model="servicecategory.nama_kategori_service"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="12" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo</v-card-text>
              <!-- <v-img :lazy-src="showPhoto()" max-height="150" max-width="150" :src="showPhoto()"></v-img> -->
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper ref="cropper" :aspect-ratio="2 / 2" :src="showPhoto()" preview=".preview" />
                </div>
              </div>

              <v-btn type="submit" color="primary" @click.prevent="showFileChooser" class="me-3 mt-4">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" @click.prevent="cropImage" class="me-3 mt-4"> Crop </v-btn>

              <input
                style="display: none"
                ref="input"
                type="file"
                :name="servicecategory.logo"
                accept="image/*"
                @change="setImage"
              />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Logo</v-card-text>

              <section class="preview-area">
                <!-- <p>Cover</p> -->
                <div class="cropped-image">
                  <img width="200px" :src="updatePhoto()" />
                </div>
              </section>
            </v-col>

            <v-col md="12" cols="12">
              <v-header>Active</v-header>
              <v-radio-group v-model="servicecategory.aktif" row>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col md="6" cols="12">
              <v-header>Kontak</v-header>
              <v-radio-group v-model="servicecategory.kontak" column>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'categoryservice-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiEmailOutline, mdiCellphone, mdiCamera, mdiSlashForward } from '@mdi/js'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Category Service',
          disabled: false,
          href: '/admin/servicecategory',
        },
        {
          text: 'Create Category Service',
          disabled: true,
        },
      ],
      icons: {
        mdiCheckBold,
        mdiEmailOutline,
        mdiCellphone,
        mdiCamera,
        mdiSlashForward,
      },
      // rules: [value => !value || value.size < 2000000 || 'Photo size should be less than 2 MB!'],
      // statuspernikahan: ['Belum Menikah', 'Menikah', 'Bercerai'],
      // nama_kewarganegaraan: '',
      servicecategory: [],
      errors: [],
    }
  },
  mounted() {
    this.$isLoading(true)

    setTimeout(() => {
      this.getAllServiceCategory()
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getAllServiceCategory() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uriClients =
        process.env.VUE_APP_ROOT_API + `/api/kategoriservices/${this.$route.params.id_kategoriservice}/show`
      this.axios.get(uriClients).then(response => {
        this.servicecategory = response.data.kategoriservice
        // console.log(this.members.email)
      })
    },
    defautlPhoto() {
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/pegawai/no-image.png`
      return imageDefault
    },

    showPhoto() {
      if (typeof this.servicecategory.logo === 'string' && this.servicecategory.logo.length != 0) {
        if (this.servicecategory.logo.length > 100) {
          return this.servicecategory.logo
        }
        let imageClients =
          process.env.VUE_APP_ROOT_API +
          `/images/kategori_service/${this.servicecategory.id_kategoriservice}/${this.servicecategory.logo}`
        return imageClients
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/pegawai/no-image.png`
      return imageDefault
    },

    setImage(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.servicecategory.logo = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },

    updatePhoto() {
      if (this.servicecategory.logo == null || this.servicecategory.logo == '') {
        // return "../../../../images/guru/no-image.jpg";
        let imageDefault = process.env.VUE_APP_ROOT_API + `/images/pegawai/no-image.png`
        return imageDefault
      }

      let img = this.servicecategory.logo

      if (img.length > 100) {
        return this.servicecategory.logo
      } else {
        let imageClients =
          process.env.VUE_APP_ROOT_API +
          `/images/kategori_service/${this.servicecategory.id_kategoriservice}/${this.servicecategory.logo}`
        return imageClients
      }
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.servicecategory.logo = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    showFileChooser() {
      this.$refs.input.click()
    },

    CreateData(e) {
      // console.log('logo: ' + this.servicecategory.logo)
      if (this.$data.servicecategory.nama_kategori_service.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Category service name required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        // let uri = process.env.VUE_APP_ROOT_API + '/api/kategoriservices/store'
        let uri = process.env.VUE_APP_ROOT_API + `/api/kategoriservices/${this.$route.params.id_kategoriservice}`

        this.axios
          .post(uri, {
            nama_kategori_service: this.servicecategory.nama_kategori_service,
            logo: this.servicecategory.logo,
            aktif: this.servicecategory.aktif,
            kontak: this.servicecategory.kontak,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Category service name created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            // this.$router.push({ name: 'users-index' })
            setTimeout(() => {
              this.$router.push({ name: 'servicecategory-index' })
            }, 3000)
          })
          .catch()

        e.preventDefault()
      }
    },
  },
}
</script>
